const currencyNoCentsFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const currencyWithCentsFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export function toCurrency(
  dollarValue?: string | number | null,
  options?: { withCents?: boolean; withSymbol?: boolean }
) {
  if (
    dollarValue === undefined ||
    dollarValue === null ||
    isNaN(Number(dollarValue))
  ) {
    return '';
  }

  const withCents = options?.withCents ?? false;

  const formatter = withCents
    ? currencyWithCentsFormatter
    : currencyNoCentsFormatter;

  const result = formatter.format(Number(dollarValue));
  if (options?.withSymbol === false) {
    return result.slice(1);
  }

  return result;
}

export function centsToCurrency(
  centsValue?: string | number | null,
  options?: { withCents?: boolean; withSymbol?: boolean }
) {
  if (
    centsValue === undefined ||
    centsValue === null ||
    isNaN(Number(centsValue))
  ) {
    return '';
  }

  return toCurrency(Number(centsValue) / 100, options);
}
